import router from "./router";
import store from "./store";
import { SERVER_ERROR_CODES } from "./Utils";

const SET_ERROR = (
  error,
  {
    titleText = null,
    titleParams = null,
    messageText = null,
    messageParams = null,
    fallbackRoute = ""
  }
) => {
  let isAuthError = false,
    errorStatus = 0,
    errorCode = 0;
  if (error && error.response && error.response.status) {
    errorStatus = error.response.status;
    isAuthError = errorStatus === SERVER_ERROR_CODES.UNAUTHORIZED;
    if (error.response.body) {
      errorCode = error.response.body.errorCode;
    }
  }
  store.commit(
    "alert/SET_ALERT",
    {
      type: "error",
      status: errorStatus,
      errorCode,
      titleText,
      titleParams,
      messageText,
      messageParams
    },
    { root: true }
  );
  if (isAuthError) {
    store.commit("auth/SET_IS_LOGGED_IN", false);
    store.commit("auth/SET_SESSION_TOKEN", "");
    router.replace({ name: "Login", params: { hasAlert: true } });
  } else if (fallbackRoute && router.history.current.name !== fallbackRoute)
    router.push({ name: fallbackRoute, params: { hasAlert: true } });
  else store.commit("loader/RESET");
};

export { SET_ERROR };
