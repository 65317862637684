export default {
  computed: {
    $_authUser() {
      return this.$store.getters["auth/getAdminInfo"];
    },
    $_authUserIsDomi() {
      return this.$_authUser.type === "domi";
    },
    $_authUserIsMaintainer() {
      return this.$_authUser.type === "maintainer";
    }
  }
};
