/* eslint prefer-promise-reject-errors: ["error", {"allowEmptyReject": true}] */
import services from "./../services";
import { SET_ERROR } from "./../errorHandler";

const getDefaultState = () => {
  return {
    consumerUsers: [],
    currentConsumerUser: {},
    currentConsumerUserGroups: [],
    filters: {
      search: "",
      userStatus: null,
      blocked: false
    },
    sortBy: "fullName",
    sortDesc: false
  };
};
const state = getDefaultState();

const getters = {
  getConsumerUsers: state => state.consumerUsers,
  getCurrentConsumerUser: state => state.currentConsumerUser,
  getCurrentConsumerUserGroups: state => state.currentConsumerUserGroups,

  getFilterSearch: state => state.filters.search,
  getFilterUserStatus: state => state.filters.userStatus,
  getFilterBlocked: state => state.filters.blocked,

  getSortBy: state => state.sortBy,
  getSortDesc: state => state.sortDesc
};

const mutations = {
  RESET(state) {
    const s = getDefaultState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  SET_CONSUMER_USERS(state, users) {
    state.consumerUsers = users;
  },
  SET_CURRENT_CONSUMER_USER(state, currenUser) {
    state.currentConsumerUser = currenUser;
  },
  SET_CURRENT_CONSUMER_USER_GROUPS(state, currentGroups) {
    state.currentConsumerUserGroups = currentGroups;
  },
  SET_FILTER_SEARCH(state, search) {
    state.filters.search = search;
  },
  SET_FILTER_USER_STATUS(state, userStatus) {
    state.filters.userStatus = userStatus;
  },
  SET_FILTER_BLOCKED(state, blocked) {
    state.filters.blocked = blocked;
  },
  SET_SORT_BY(state, sortBy) {
    state.sortBy = sortBy;
  },
  SET_SORT_DESC(state, sortDesc) {
    state.sortDesc = sortDesc;
  }
};

const actions = {
  async retrieveConsumerUsers({ commit, getters }) {
    try {
      const parameters = {
        type: "consumer",
        search: getters.getFilterSearch,
        isBlocked: getters.getFilterBlocked
      };
      if (getters.getFilterUserStatus) {
        parameters.status = getters.getFilterUserStatus;
      }
      const response = await services.UserService.retrieveUsers(parameters);
      commit("SET_CONSUMER_USERS", response);
    } catch (error) {
      console.log(error?.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async retrieveConsumerUser({ commit }, userId) {
    try {
      const response = await services.UserService.retrieveUser(userId);
      commit("SET_CURRENT_CONSUMER_USER", response.user);
      commit("SET_CURRENT_CONSUMER_USER_GROUPS", response.groupsMemberships);
    } catch (error) {
      console.log(error?.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async deleteConsumerUser({ commit }, userId) {
    try {
      await services.UserService.deleteUser(userId);
    } catch (error) {
      console.log(error?.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  }
};

export default {
  namespaced: true,
  strict: true,
  state,
  getters,
  mutations,
  actions
};
