import Vue from "vue";
import App from "./App.vue";
import VueLazyLoad from "vue-lazyload";
import VueHead from "vue-head";
import "./plugins/bootstrap-vue";
import "./plugins/vee-validate";

import router from "./router";
import store from "./store";
import i18n from "./plugins/i18n";

import dayjs from "dayjs";
import "dayjs/locale/it";
import "dayjs/locale/en";

Vue.config.productionTip = false;

Vue.use(VueLazyLoad);
Vue.use(VueHead);

Vue.filter("date", (date, format) => {
  const dayjsDate = dayjs(String(date));
  return dayjsDate.isValid()
    ? dayjsDate.locale(i18n.locale).format(format)
    : "-";
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
