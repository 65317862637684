import swaggerClient from "./../plugins/swagger-client";
export default class ParametersService {
  createIssueCategory(payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis["Issue Categories"].createIssueCategory(
          {},
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  retrieveIssueCategories(parameters) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis["Issue Categories"].retrieveIssueCategories(
          parameters,
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  updateIssueCategory(issueCategoryId, payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis["Issue Categories"].updateIssueCategory(
          { issueCategoryId },
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }
}
