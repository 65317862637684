import dotenv from "dotenv";
dotenv.config();

export default class ConfigProvider {
  static get CONFIG() {
    return {
      backendProtocol: "$VUE_APP_API_PROTOCOL",
      backendHost: "$VUE_APP_API_HOST",
      backendPort: "$VUE_APP_API_PORT",
      backendBasePath: "$VUE_APP_API_BASE_PATH",
      backendPrefix: "$VUE_APP_API_PREFIX",
      googleKeyMap: "$VUE_APP_GOOGLE_KEY_MAPS",
      xApplicationId: "$VUE_APP_X_APPLICATION_ID",
      requestidReporting: "$VUE_APP_REQUESTID_REPORTING",
      secretTokenKey: "$VUE_APP_SECRET_TOKEN_KEY",
      recaptchaSitekey: "$VUE_APP_RECAPTCHA_SITEKEY",
      administratorThankPage: "$VUE_APP_THANKS_PAGE_ADMINISTRATOR",
      maintainerThankPage: "$VUE_APP_THANKS_PAGE_MAINTAINER",
      domiAppType: "$VUE_APP_DOMI_APP_TYPE"
    };
  }

  static value(name) {
    if (!(name in this.CONFIG)) {
      return;
    }

    const value = this.CONFIG[name];

    if (!value) {
      return;
    }

    if (value.startsWith("$VUE_APP_")) {
      const envName = value.substr(1);
      const envValue = process.env[envName];
      if (envValue) {
        return envValue;
      }
    } else {
      return value;
    }
  }
}
