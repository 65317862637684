const strict = true;

const getDefaultState = () => {
  return {
    active: false,
    title: {
      text: "",
      params: ""
    },
    message: {
      params: null
    },
    cancelBtn: {
      label: "",
      callback: null
    }
  };
};

const state = getDefaultState();

const getters = {
  isActive: state => state.active,
  getTitleText: state => state.title.text,
  getTitleParams: state => state.title.params,
  getMessageParams: state => state.message.params,
  getCancelBtnLabel: state => state.cancelBtn.label,
  getCancelBtnClb: state => state.cancelBtn.callback
};

const mutations = {
  RESET(state) {
    const s = getDefaultState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  SET_ACTIVE(state, active) {
    state.active = active;
  },
  SET_BLOCKED_MODAL(
    state,
    {
      titleText = "blockList.defaultTitle",
      titleParams = null,
      messageParams = null,
      cancelBtnLabel = "button.close",
      cancelBtnCallback = null
    }
  ) {
    state.active = true;
    state.title.text = titleText;
    state.title.params = titleParams;
    state.message.params = messageParams;
    state.cancelBtn.label = cancelBtnLabel;
    state.cancelBtn.callback = cancelBtnCallback;
  }
};

const actions = {
  cancelAction({ getters, commit }) {
    const cancelClb = getters.getCancelBtnClb;
    if (isFunction(cancelClb)) cancelClb();

    commit("RESET");
  }
};

function isFunction(functionToCheck) {
  return (
    functionToCheck && {}.toString.call(functionToCheck) === "[object Function]"
  );
}

export default {
  namespaced: true,
  strict,
  state,
  getters,
  mutations,
  actions
};
