import ConfigProvider from "../plugins/config-provider";

export const appType = ConfigProvider.value("domiAppType");
export const isAppTypeOneBoard = appType === "1BOARD";
export const isAppTypeDomi = !isAppTypeOneBoard;

export default {
  isAppTypeOneBoard,
  isAppTypeDomi
};
