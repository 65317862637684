/* eslint prefer-promise-reject-errors: ["error", {"allowEmptyReject": true}] */
import services from "./../services";

const strict = true;

const getDefaultState = () => {
  return {
    notifications: [],
    notificationNum: 0,
    chatNotifications: [],
    chatNotificationNum: 0
  };
};

const state = getDefaultState();

const getters = {
  notifications: state => state.notifications,
  notificationNum: state => state.notificationNum,
  chatNotificationNum: state => state.chatNotificationNum,
  chatNotifications: state => state.chatNotifications,
  totalNotificationNum: state =>
    state.notificationNum + state.chatNotificationNum
};

const mutations = {
  RESET(state) {
    const s = getDefaultState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  SET_NOTIFICATIONS(state, val) {
    state.notifications = val;
  },
  SET_NEW_NOTIFICATIONS_COUNT(state, val) {
    state.notificationNum = val;
  },
  UPDATE_NEW_NOTIFICATIONS_COUNT(state) {
    state.notificationNum -= 1;
  },
  UPDATE_NOTIFICATION(state, notification) {
    const index = state.notifications.findIndex(
      n => n._id === notification._id
    );
    if (index > -1) state.notifications[index] = notification;
  }
};

const actions = {
  createNotification({ commit }, payload) {
    return services.NotificationService.createNotification(payload)
      .then(response => {})
      .catch(() => {
        return Promise.reject();
      });
  },
  retrieveNotifications({ commit }) {
    return services.NotificationService.retrieveNotifications()
      .then(response => {
        const unreadNotifications = response.reduce((a, b) => {
          return a + Number(b.status === "new");
        }, 0);
        commit("SET_NOTIFICATIONS", response);
        commit("SET_NEW_NOTIFICATIONS_COUNT", unreadNotifications);
      })
      .catch(() => {
        return Promise.reject();
      });
  },
  retrieveNotification({ commit }, notificationId) {
    return services.NotificationService.retrieveNotification(notificationId)
      .then(response => {})
      .catch(() => {
        return Promise.reject();
      });
  },
  updateNotification({ commit }, { notificationId, payload }) {
    return services.NotificationService.updateNotification(
      notificationId,
      payload
    )
      .then(response => {
        return response;
      })
      .catch(() => {
        return Promise.reject();
      });
  },
  deleteNotification({ commit }, notificationId) {
    return services.NotificationService.deleteNotification(notificationId)
      .then(response => {})
      .catch(() => {
        return Promise.reject();
      });
  },
  markChatNotificationsRead({ commit, dispatch, getters }) {
    try {
      const chatNotifications = getters.chatNotifications;
      for (const cn of chatNotifications) {
        if (cn.status === "new") {
          dispatch("updateNotification", {
            notificationId: cn._id,
            payload: {
              title: cn.title,
              body: cn.body,
              type: cn.type,
              status: "read"
            }
          }).then(res => {
            commit("UPDATE_CHAT_NOTIFICATION", res);
          });
        }
      }
      commit("SET_CHAT_NOTIFICATIONS_COUNT", 0);
    } catch (error) {
      console.error(error);
    }
  },
  markNotificationsRead({ commit, dispatch, getters }) {
    try {
      const notifications = getters.notifications;
      for (const n of notifications) {
        if (n.status === "new") {
          dispatch("updateNotification", {
            notificationId: n._id,
            payload: {
              title: n.title,
              body: n.body,
              type: n.type,
              status: "read"
            }
          }).then(res => {
            commit("UPDATE_NOTIFICATION", res);
          });
        }
        dispatch("retrieveNotifications");
      }
      commit("SET_NEW_NOTIFICATIONS_COUNT", 0);
    } catch (error) {
      console.error(error);
    }
  }
};

export default {
  namespaced: true,
  strict,
  state,
  getters,
  mutations,
  actions
};
