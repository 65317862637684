/* eslint prefer-promise-reject-errors: ["error", {"allowEmptyReject": true}] */
import services from "./../services";
import { SET_ERROR } from "./../errorHandler";

const getDefaultState = () => {
  return {
    adminUsers: [],
    currentAdminUser: {},
    sortBy: "fullName",
    sortDesc: false,
    filters: {
      search: "",
      userStatus: null
    }
  };
};
const state = getDefaultState();

const getters = {
  getAdminUsers: state => state.adminUsers,
  getCurrentAdminUser: state => state.currentAdminUser,

  getFilterSearch: state => state.filters.search,
  getFilterUserStatus: state => state.filters.userStatus,

  getSortBy: state => state.sortBy,
  getSortDesc: state => state.sortDesc
};

const mutations = {
  RESET(state) {
    const s = getDefaultState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  SET_ADMIN_USERS(state, adminUsers) {
    state.adminUsers = adminUsers;
  },
  SET_CURRENT_ADMIN_USER(state, currentAdminUser) {
    state.currentAdminUser = currentAdminUser;
  },
  SET_FILTER_SEARCH(state, search) {
    state.filters.search = search;
  },
  SET_FILTER_USER_STATUS(state, userStatus) {
    state.filters.userStatus = userStatus;
  },
  SET_SORT_BY(state, sortBy) {
    state.sortBy = sortBy;
  },
  SET_SORT_DESC(state, sortDesc) {
    state.sortDesc = sortDesc;
  }
};

const actions = {
  async retrieveAdminUsers({ commit, getters }) {
    try {
      const parameters = {
        type: "administrator",
        search: getters.getFilterSearch
      };
      if (getters.getFilterUserStatus) {
        parameters.status = getters.getFilterUserStatus;
      }
      const response = await services.UserService.retrieveUsers(parameters);
      commit("SET_ADMIN_USERS", response);
    } catch (error) {
      console.log(error?.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async retrieveAdminUser({ commit, dispatch }, userId) {
    try {
      const response = await services.UserService.retrieveUser(userId);
      commit("SET_CURRENT_ADMIN_USER", response.user);
      await dispatch("retrieveAdministrator");
    } catch (error) {
      console.log(error?.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async retrieveAdministrator({ commit, getters }) {
    const userInfo = getters.getCurrentAdminUser;
    const response = await services.AdminService.retrieveAdministrator(
      userInfo.profile.administratorId
    );
    commit("SET_CURRENT_ADMIN_USER", {
      ...userInfo,
      generalInfo: response.administrator
    });
  },
  async updateAdministrator({ commit }, { administratorId, adminInfo }) {
    try {
      await services.AdminService.updateAdministrator(
        administratorId,
        adminInfo
      );
    } catch (error) {
      console.log(error?.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  }
};

export default {
  namespaced: true,
  strict: true,
  state,
  getters,
  mutations,
  actions
};
