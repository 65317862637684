import ConfigProvider from "../plugins/config-provider";
export default {
  data() {
    return {
      logoDomi: require("../assets/domi-admin-neg.png"),
      logo1board: require("../assets/1board_logo.svg"),
      favDomi: require("../assets/favicon.png"),
      favBoard: require("../assets/board_favicon.png")
    };
  },
  computed: {
    $is1Board() {
      return ConfigProvider.value("domiAppType") === "1BOARD";
    },
    $isDomi() {
      return !this.$is1Board;
    },
    $appLogoPath() {
      return this.$is1Board ? "./assets/1board_logo.png" : "../assets/logo.png";
    },
    $appLogo() {
      return this.$is1Board ? this.logo1board : this.logoDomi;
    },
    $appFav() {
      return this.$is1Board ? this.favBoard : this.favDomi;
    }
  }
};
