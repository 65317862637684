import swaggerClient from "./../plugins/swagger-client";

export default class WallService {
  createNotification(payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Notifications.createNotification(
          {},
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  retrieveNotifications() {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Notifications.retrieveNotifications(
          {},
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  retrieveNotification(notificationId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Notifications.retrieveNotification(
          { notificationId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  updateNotification(notificationId, payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Notifications.updateNotification(
          { notificationId },
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  deleteNotification(notificationId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Notifications.deleteNotification(
          { notificationId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }
}
