/* eslint prefer-promise-reject-errors: ["error", {"allowEmptyReject": true}] */
import services from "./../services";
import { SET_ERROR } from "./../errorHandler";

const getDefaultState = () => {
  return {
    isLoggedIn: false,
    sessionToken: "",
    adminInfo: {}
  };
};
const state = getDefaultState();

const getters = {
  isLoggedIn: state => state.isLoggedIn,
  getSessionToken: state => state.sessionToken,
  getAdminInfo: state => state.adminInfo,
  isDomiUser: state => state.adminInfo.type === "domi",
  getUserId: state => state.adminInfo._id
};

const mutations = {
  RESET(state) {
    const s = getDefaultState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  SET_IS_LOGGED_IN(state, isLoggedIn) {
    state.isLoggedIn = isLoggedIn;
  },
  SET_SESSION_TOKEN(state, sessionToken) {
    state.sessionToken = "Bearer " + sessionToken;
  },
  SET_ADMIN_INFO(state, adminInfo) {
    state.adminInfo = adminInfo;
  }
};

const actions = {
  async loginUser({ commit }, credentials) {
    try {
      const response = await services.UserService.loginUser(credentials);
      commit("SET_SESSION_TOKEN", response.token);
      return response.token;
    } catch (error) {
      SET_ERROR(error, { titleText: "dialog.error.credentialsTitle" });
      return Promise.reject(error.response?.body || error);
    }
  },
  async retrieveUser({ commit, dispatch }, userId) {
    try {
      const response = await services.UserService.retrieveUser(userId);
      commit("SET_ADMIN_INFO", response.user);
      if (response.user.type === "administrator")
        await dispatch("retrieveAdministrator");
      // else if (response.user.type === "maintainer")
      //   await dispatch("retrieveMaintainer");
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async retrieveAdministrator({ commit, getters }) {
    const userInfo = getters.getAdminInfo;
    const response = await services.AdminService.retrieveAdministrator(
      userInfo.profile.administratorId
    );
    commit("SET_ADMIN_INFO", {
      ...userInfo,
      generalInfo: response.administrator
    });
  },
  async retrieveMaintainer({ commit, getters }) {
    const userInfo = getters.getAdminInfo;
    const response = await services.MaintainerService.retrieveMaintainer(
      userInfo.profile.maintainerId
    );
    commit("SET_ADMIN_INFO", {
      ...userInfo,
      generalInfo: response.maintainer
    });
  },
  async recoverUserPassword({ commit }, email) {
    try {
      await services.UserService.recoverUserPassword({
        email
      });
    } catch (rejected) {
      const error = rejected.response?.body || rejected;
      if (error.code === 401 && error.errorCode === 621) {
        SET_ERROR(null, {
          messageText: "dialog.error.accountNotValid"
        });
      } else {
        SET_ERROR(error, {});
      }
      return Promise.reject();
    }
  },
  async identityConfirmPost({ commit }, { tool, payload }) {
    try {
      console.log(tool);
      console.log(payload);
      await services.UserService.identityConfirmPost(tool, payload);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject(error.response?.body || error);
    }
  },
  async setUserPassword({ commit }, payload) {
    try {
      const tool = "email";
      await services.UserService.confirmUser(tool, payload);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async updateUser({ commit }, { userId, adminInfo }) {
    try {
      await services.UserService.updateUser(userId, adminInfo);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async deleteAvatar({ commit, getters }) {
    try {
      const userId = getters.getAdminInfo._id;
      await services.UserService.deleteAvatar(userId);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  }
};

export default {
  namespaced: true,
  strict: true,
  state,
  getters,
  mutations,
  actions
};
