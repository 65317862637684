import Vue from "vue";
// import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import {
  BootstrapVueIcons,
  BVConfigPlugin,
  BContainer,
  BRow,
  BCol,
  BButton,
  BCard,
  BCardTitle,
  BCardBody,
  BCardText,
  BCardFooter,
  BCardImg,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BInputGroupText,
  BFormInvalidFeedback,
  BFormCheckboxGroup,
  BFormCheckbox,
  BFormRadioGroup,
  BFormRadio,
  BFormSelect,
  BFormSelectOption,
  BFormDatepicker,
  BNavbar,
  BNav,
  BNavbarNav,
  BNavbarBrand,
  BNavbarToggle,
  BNavItem,
  BNavText,
  BTabs,
  BTab,
  BImg,
  BModal,
  BBreadcrumb,
  BCollapse,
  BLink,
  BFormFile,
  BListGroup,
  BListGroupItem,
  BFormTextarea,
  BTable,
  BProgress,
  BOverlay,
  BAvatar,
  BSidebar,
  BBadge,
  BAlert,
  BSpinner,
  VBHover,
  VBToggle,
  VBVisible,
  ModalPlugin,
  DropdownPlugin,
  BPopover
} from "bootstrap-vue";

Vue.use(BVConfigPlugin, {
  BButton: { variant: "primary" }
});

Vue.use(ModalPlugin);
Vue.use(DropdownPlugin);

Vue.component("b-container", BContainer);
Vue.component("b-row", BRow);
Vue.component("b-col", BCol);

Vue.component("b-button", BButton);

Vue.component("b-card", BCard);
Vue.component("b-card-body", BCardBody);
Vue.component("b-card-title", BCardTitle);
Vue.component("b-card-text", BCardText);
Vue.component("b-card-footer", BCardFooter);
Vue.component("b-card-img", BCardImg);

Vue.component("b-form", BForm);
Vue.component("b-form-group", BFormGroup);
Vue.component("b-form-input", BFormInput);
Vue.component("b-input-group", BInputGroup);
Vue.component("b-input-group-append", BInputGroupAppend);
Vue.component("b-input-group-prepend", BInputGroupPrepend);
Vue.component("b-input-group-text", BInputGroupText);
Vue.component("b-form-radio-group", BFormRadioGroup);
Vue.component("b-form-radio", BFormRadio);
Vue.component("b-form-invalid-feedback", BFormInvalidFeedback);
Vue.component("b-form-checkbox-group", BFormCheckboxGroup);
Vue.component("b-form-checkbox", BFormCheckbox);
Vue.component("b-form-select", BFormSelect);
Vue.component("b-form-select-option", BFormSelectOption);
Vue.component("b-form-datepicker", BFormDatepicker);

Vue.component("b-navbar", BNavbar);
Vue.component("b-nav", BNav);
Vue.component("b-navbar-nav", BNavbarNav);
Vue.component("b-navbar-brand", BNavbarBrand);
Vue.component("b-navbar-toggle", BNavbarToggle);
Vue.component("b-nav-item", BNavItem);
Vue.component("b-nav-text", BNavText);

Vue.component("b-tabs", BTabs);
Vue.component("b-tab", BTab);

Vue.component("b-img", BImg);
Vue.component("b-modal", BModal);
Vue.component("b-breadcrumb", BBreadcrumb);
Vue.component("b-collapse", BCollapse);
Vue.component("b-link", BLink);
Vue.component("b-form-file", BFormFile);
Vue.component("b-list-group", BListGroup);
Vue.component("b-list-group-item", BListGroupItem);
Vue.component("b-form-textarea", BFormTextarea);
Vue.component("b-table", BTable);
Vue.component("b-progress", BProgress);
Vue.component("b-overlay", BOverlay);
Vue.component("b-avatar", BAvatar);
Vue.component("b-sidebar", BSidebar);
Vue.component("b-badge", BBadge);
Vue.component("b-alert", BAlert);
Vue.component("b-spinner", BSpinner);
Vue.component("b-popover", BPopover);

Vue.directive("b-hover", VBHover);
Vue.directive("b-toggle", VBToggle);
Vue.directive("b-visible", VBVisible);
// Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
