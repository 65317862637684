import swaggerClient from "./../plugins/swagger-client";

export default class AdminService {
  retrieveAdministrator(administratorId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Administrators.retrieveAdministrator(
          { administratorId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  updateAdministrator(administratorId, payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Administrators.updateAdministrator(
          { administratorId },
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }
}
