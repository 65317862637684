<template>
  <div id="app" class="bg-primary-bg-color">
    <router-view />
  </div>
</template>

<script>
import Vue from "vue";
import { version } from "../package.json";
import { appMixin } from "./mixins";

var v = new Vue({
  mixins: [appMixin]
});
console.log(v.$appFav);

export default {
  mixins: [appMixin],
  head: {
    link: [
      {
        rel: "icon",
        href: v.$appFav,
        sizes: "16x16"
      },
      {
        rel: "icon",
        href: v.$appFav,
        sizes: "32x32"
      }
    ]
  },
  created() {
    this.$store.commit("info/SET_APP_VERSION", version);
  }
};
</script>

<style lang="scss">
// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
//   margin-top: 60px;
// }
:root {
  --top-menu-height: 60px;
  --top-menu-z-index: 4;
  --side-menu-z-index: 3;
}
@import "~vue-image-lightbox/dist/vue-image-lightbox.min.css";
@import "assets/custom-bootstrap-vars.scss";
@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap-vue/src/index.scss";
</style>
<style>
.clickable {
  cursor: pointer;
}
</style>
