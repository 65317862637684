import swaggerClient from "./../plugins/swagger-client";

export default class UserService {
  loginUser(payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis["Users - Authentication"].loginUser(
          {},
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  createUser(payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Users.createUser(
          {},
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  updateUser(userId, payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Users.updateUser(
          { userId },
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  deleteUser(userId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Users.deleteUser(
          { userId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  deleteAvatar(userId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Users.deleteAvatar(
          { userId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  retrieveUsers(parameters = {}) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Users.retrieveUsers(
          parameters,
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  retrieveUser(userId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Users.retrieveUser(
          { userId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  identityConfirm(parameters) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis["Users - Authentication"].identityConfirm(
          parameters,
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  confirmUser(tool, payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis["Users - Authentication"].confirmUser(
          { tool },
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  recoverUserPassword(payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis["Users - Authentication"].recoverUserPassword(
          {},
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  identityConfirmPost(tool, payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis["Users - Authentication"].identityConfirmPost(
          { tool },
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  uploadCondominium(condominiumId, file) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Users.uploadCondominium(
          { condominiumId },
          swaggerClient.buildRequestBody({ file }),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  removeUserFromCondominium(userId, condominiumId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Users.removeCondominium(
          { userId, condominiumId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  addUserCondominium(userId, condominiumId, payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Users.addCondominium(
          { userId, condominiumId },
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  updateUserCondominium(userId, condominiumId, payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Users.updateUserCondominium(
          { userId, condominiumId },
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  suspendUser(userId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Users.suspendUser(
          { userId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  reactivateUser(userId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Users.reactivateUser(
          { userId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }
}
