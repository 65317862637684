import SwaggerClient from "swagger-client";
import { v4 as uuidv4 } from "uuid";
import store from "./../store";
import ConfigProvider from "./config-provider";

console.log("Test : " + ConfigProvider.value("backendProtocol"));
console.log("Test : " + ConfigProvider.value("administratorThankPage"));

const requestidReporting = ConfigProvider.value("requestidReporting");
let xRequestId = "";

const buildRequestBody = function(requestBody) {
  return {
    requestBody: requestBody,
    server: "{protocol}://{host}:{port}{basePath}{apiPrefix}",
    serverVariables: {
      protocol: ConfigProvider.value("backendProtocol"),
      host: ConfigProvider.value("backendHost"),
      port: ConfigProvider.value("backendPort"),
      basePath: ConfigProvider.value("backendBasePath"),
      apiPrefix: ConfigProvider.value("backendPrefix")
    }
  };
};

const swagger = new SwaggerClient("/domi.v1.json", {
  requestInterceptor: request => {
    const sessionToken = store && store.getters["auth/getSessionToken"];
    if (sessionToken) request.headers.Authorization = sessionToken;

    request.headers["x-application-id"] = ConfigProvider.value(
      "xApplicationId"
    );

    xRequestId = uuidv4();
    request.headers["x-request-id"] = xRequestId;

    if (store && store.getters["general/getAppLang"])
      request.headers["accept-language"] = store.getters["general/getAppLang"];

    return request;
  },
  responseInterceptor: () => {
    if (requestidReporting === "true") {
      console.log(
        "%c X-Request-Id: " + xRequestId,
        "background: #f4f4cd; color: black; font-weight: bold;"
      );
    }
  }
});

export default { swagger, buildRequestBody };
