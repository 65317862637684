/* eslint prefer-promise-reject-errors: ["error", {"allowEmptyReject": true}] */
import { version } from "../../package.json";
const getDefaultState = () => {
  return {
    appVersion: version
  };
};
const state = getDefaultState();

const getters = {
  getAppVersion: state => state.appVersion
};

const mutations = {
  RESET(state) {
    const s = getDefaultState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  SET_APP_VERSION(state, version) {
    state.appVersion = version;
  }
};

const actions = {};

export default {
  namespaced: true,
  strict: true,
  state,
  getters,
  mutations,
  actions
};
