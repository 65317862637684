import UserService from "./services/UserService";
import CondominiumService from "./services/CondominiumService";
import CommunicationService from "./services/CommunicationService";
import IssueService from "./services/IssueService";
import MaintainerService from "./services/MaintainerService";
import AdminService from "./services/AdminService";
import PostService from "./services/PostService";
import GroupService from "./services/GroupService";
import ParametersService from "./services/ParametersService";
import NotificationService from "./services/NotificationService";

export default {
  UserService: new UserService(),
  CondominiumService: new CondominiumService(),
  CommunicationService: new CommunicationService(),
  IssueService: new IssueService(),
  MaintainerService: new MaintainerService(),
  AdminService: new AdminService(),
  PostService: new PostService(),
  GroupService: new GroupService(),
  ParametersService: new ParametersService(),
  NotificationService: new NotificationService()
};
