/* eslint prefer-promise-reject-errors: ["error", {"allowEmptyReject": true}] */
import services from "./../services";
import { SET_ERROR } from "./../errorHandler";

const getDefaultState = () => {
  return {
    logo: null,
    categories: []
  };
};
const state = getDefaultState();

const getters = {
  getLogo: state => state.logo,
  getCategories: state => state.categories,
  getActiveCategories: state => {
    const activeCategories = [];
    state.categories.forEach(category => {
      if (category.active) activeCategories.push(category);
    });
    return activeCategories;
  }
};

const mutations = {
  RESET(state) {
    const s = getDefaultState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  SET_LOGO(state, logo) {
    state.logo = logo;
  },
  SET_CATEGORIES(state, categories) {
    state.categories = categories;
  }
};

const actions = {
  async retrieveCategories({ commit, getters, rootGetters }) {
    try {
      const parameters = {};
      if (
        rootGetters["auth/isDomiUser"] &&
        rootGetters["filter/getAdministratorSelected"] !== null
      ) {
        parameters.administratorId =
          rootGetters["filter/getAdministratorSelected"]._id;
      }
      const response = await services.ParametersService.retrieveIssueCategories(
        parameters
      );
      commit("SET_CATEGORIES", response);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async createCategory({ commit }, categoryDetails) {
    try {
      await services.ParametersService.createIssueCategory(categoryDetails);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async updateCategory({ commit }, { issueCategoryId, payload }) {
    try {
      await services.ParametersService.updateIssueCategory(
        issueCategoryId,
        payload
      );
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async updateLogo({ commit }, { payload }) {
    try {
      console.log("payload: ", payload);
      commit("SET_LOGO", payload);
      return Promise.resolve();
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  }
};

export default {
  namespaced: true,
  strict: true,
  state,
  getters,
  mutations,
  actions
};
