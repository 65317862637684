import Vue from "vue";
import VueI18n from "vue-i18n";
import store from "./../store";
import ConfigProvider from "./config-provider";

Vue.use(VueI18n);

function loadLocaleMessages() {
  let locales;
  if (ConfigProvider.value("domiAppType") === "1BOARD") {
    locales = require.context(
      "./../locales/1board",
      true,
      /[A-Za-z0-9-_,\s]+\.json$/i
    );
  } else {
    locales = require.context(
      "./../locales/domi",
      true,
      /[A-Za-z0-9-_,\s]+\.json$/i
    );
  }

  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      // get locale from complex filename (filename 'locale-dataName')
      const formattedLocale = locale.split("-")[0];

      // concat all translations from all pages
      messages[formattedLocale] = {
        ...messages[formattedLocale],
        ...locales(key)
      };
    }
  });
  return messages;
}

const language = store.getters["general/getAppLang"];

export default new VueI18n({
  locale: language || "en",
  fallbackLocale: ConfigProvider.value("i18nFallbackLocale") || "en",
  messages: loadLocaleMessages()
});
