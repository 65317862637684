import swaggerClient from "../plugins/swagger-client";

export default class CommunicationService {
  retrieveCommunicationByToken(token) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Communications.retrieveCommunicationByToken(
          { token },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  readCommunicationByToken(token, payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Communications.readCommunicationByToken(
          { token },
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  createCommunication(condominiumId, payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Communications.createCommunication(
          { condominiumId },
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  retrieveCommunications(condominiumId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Communications.retrieveCommunications(
          { condominiumId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  retrieveCommunication(condominiumId, communicationId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Communications.retrieveCommunication(
          { condominiumId, communicationId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  updateCommunication(condominiumId, communicationId, payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Communications.updateCommunication(
          { condominiumId, communicationId },
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  deleteCommunication(condominiumId, communicationId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Communications.deleteCommunication(
          { condominiumId, communicationId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  retrieveUserCommunications(parameters = {}) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Communications.retrieveUserCommunications(
          parameters,
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  sendCommunication(condominiumId, communicationId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Communications.sendCommunication(
          { condominiumId, communicationId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  addCommunicationRecipients(condominiumId, communicationId, payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Communications.addCommunicationRecipients(
          { condominiumId, communicationId },
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  createCommunicationRecipient(condominiumId, communicationId, payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Communications.createCommunicationRecipient(
          { condominiumId, communicationId },
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  updateCommunicationRecipient(
    condominiumId,
    communicationId,
    recipientId,
    payload
  ) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Communications.updateCommunicationRecipient(
          { condominiumId, communicationId, recipientId },
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  deleteCommunicationRecipient(condominiumId, communicationId, recipientId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Communications.deleteCommunicationRecipient(
          { condominiumId, communicationId, recipientId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  addDocuments(condominiumId, communicationId, payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Documents.addDocuments(
          { condominiumId, communicationId },
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  deleteDocument(condominiumId, communicationId, documentId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Documents.deleteDocument(
          { condominiumId, communicationId, documentId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  receiptsReport(condominiumId, communicationId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Communications.receiptsReport(
          { condominiumId, communicationId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  paperCommunication(condominiumId, communicationId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Communications.paperCommunication(
          { condominiumId, communicationId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }
}
