/* eslint prefer-promise-reject-errors: ["error", {"allowEmptyReject": true}] */
import services from "./../services";
import { MAINTAINER_TYPES } from "./../Utils";
import { SET_ERROR } from "./../errorHandler";

const getDefaultState = () => {
  return {
    maintainers: [],
    adminMaintainers: [],
    filters: {
      search: "",
      type: []
    },
    typeOptions: MAINTAINER_TYPES
  };
};
const state = getDefaultState();

const getters = {
  getMaintainers: state => state.maintainers,
  getAdminMaintainers: state => state.adminMaintainers,

  getTypeOptions: state => state.typeOptions,

  getFilterSearch: state => state.filters.search,
  getFilterType: state => state.filters.type
};

const mutations = {
  RESET(state) {
    const s = getDefaultState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  SET_MAINTAINERS(state, maintainers) {
    state.maintainers = maintainers;
  },
  SET_ADMIN_MAINTAINERS(state, adminMaintainers) {
    state.adminMaintainers = adminMaintainers;
  },
  SET_FILTER_SEARCH(state, search) {
    state.filters.search = search;
  },
  SET_FILTER_TYPE(state, type) {
    state.filters.type = type;
  }
};

const actions = {
  async retrieveMaintainers({ commit, getters, rootGetters }) {
    try {
      const parameters = {
        search: getters.getFilterSearch,
        type: getters.getFilterType.join(",")
      };
      const response = await services.MaintainerService.retrieveMaintainers(
        parameters
      );

      let maintainers = response.filter(item => item.placeId);

      const user = rootGetters["auth/getAdminInfo"];
      if (user.type === "administrator") {
        maintainers = maintainers.filter(
          m => m.userId && m.userId.status === "active"
        );
      }
      commit("SET_MAINTAINERS", maintainers);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async retrieveAdminMaintainers({ commit }) {
    try {
      const response = await services.MaintainerService.retrieveAdminMaintainers();
      commit(
        "SET_ADMIN_MAINTAINERS",
        response.filter(item => {
          return item.placeId;
        })
      );
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async addAdminMaintainer({ commit }, maintainerId) {
    try {
      await services.MaintainerService.addAdminMaintainer(maintainerId);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async deleteAdminMaintainer({ commit }, maintainerId) {
    try {
      await services.MaintainerService.deleteAdminMaintainer(maintainerId);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  }
};

export default {
  namespaced: true,
  strict: true,
  state,
  getters,
  mutations,
  actions
};
