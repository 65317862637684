import swaggerClient from "./../plugins/swagger-client";

export default class MaintainerService {
  retrieveMaintainers(parameters = {}) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Maintainers.retrieveMaintainers(
          parameters,
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  retrieveMaintainer(maintainerId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Maintainers.retrieveMaintainer(
          { maintainerId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  retrieveAdminMaintainers(parameters = {}) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Maintainers.retrieveAdminMaintainers(
          parameters,
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  addAdminMaintainer(maintainerId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Maintainers.addAdminMaintainer(
          { maintainerId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  deleteAdminMaintainer(maintainerId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Maintainers.deleteAdminMaintainer(
          { maintainerId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }
}
