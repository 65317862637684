import swaggerClient from "./../plugins/swagger-client";

export default class GroupService {
  createGroup(payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Groups.createGroup(
          {},
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  retrieveGroups(parameters) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Groups.retrieveGroups(
          parameters,
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  retrieveGroup(groupId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Groups.retrieveGroup(
          { groupId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  addGroupMember(groupId, payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Groups.addGroupMembers(
          { groupId },
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  updateGroup(groupId, payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Groups.updateGroup(
          { groupId },
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  retrieveGroupUsers(parameters = {}) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Groups.retrieveGroupMembers(
          parameters,
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  removeGroupMembers(groupId, payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Groups.removeGroupMembers(
          { groupId },
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  deleteGroup(groupId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Groups.deleteGroup(
          { groupId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }
}
