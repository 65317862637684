/* eslint prefer-promise-reject-errors: ["error", {"allowEmptyReject": true}] */

const strict = true;

const getDefaultState = () => {
  return {
    active: false,
    type: "", // [success, warning, error, ""]
    title: {
      text: "", // String ( e.g "success.defaultTitle" )
      params: null // Object ( e.g. {key1: "value1", key2: "value2"} )
    },
    message: {
      text: "", // String ( e.g "success.defaultMsg" )
      params: null // Object ( e.g. {key1: "value1", key2: "value2"} )
    }
  };
};

const state = getDefaultState();

const getters = {
  isActive: state => state.active,
  getType: state => state.type,
  getTitleText: state => state.title.text,
  getTitleParams: state => state.title.params,
  getMessageText: state => state.message.text,
  getMessageParams: state => state.message.params
};

const mutations = {
  RESET(state) {
    const s = getDefaultState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  SET_ALERT(
    state,
    {
      status = 0,
      errorCode = 0,
      type = "",
      titleText = null,
      titleParams = null,
      messageText = null,
      messageParams = null
    }
  ) {
    state.active = true;
    state.type = type;

    // Set title
    if (titleText !== null) {
      state.title.text = titleText;
      state.title.params = titleParams;
    } else if (state.type === "success") {
      state.title = getDefaultSuccessTitle();
    } else if (state.type === "warning") {
      state.title = getDefaultWarningTitle();
    } else {
      state.title = getDefaultErrorTitle(status);
    }

    // Set message
    if (messageText !== null) {
      state.message.text = messageText;
      state.message.params = messageParams;
    } else if (state.type === "success") {
      state.message = getDefaultSuccessMessage();
    } else if (state.type === "warning") {
      state.message = getDefaultWarningMessage();
    } else {
      state.message = getDefaultErrorMessage(status, errorCode);
    }
  }
};

const actions = {
  close({ commit }) {
    commit("RESET");
  }
};

function getDefaultSuccessTitle() {
  return {
    text: "dialog.success.defaultTitle",
    params: null
  };
}

function getDefaultSuccessMessage() {
  return {
    text: "dialog.success.defaultMsg",
    params: null
  };
}

function getDefaultWarningTitle() {
  return {
    text: "dialog.warning.defaultTitle",
    params: null
  };
}

function getDefaultWarningMessage() {
  return {
    text: "dialog.warning.defaultMsg",
    params: null
  };
}

function getDefaultErrorTitle(status) {
  switch (status) {
    case 401: // Authorization
      return {
        text: "dialog.error.unauthorizedTitle",
        params: null
      };
    default:
      return {
        text: "dialog.error.defaultTitle",
        params: null
      };
  }
}
function getDefaultErrorMessage(status, errorCode) {
  switch (status) {
    case 400: // Bad request
      return getDefaultErrorCodeMessage(errorCode);
    case 401: // Authorization
      if (errorCode) {
        return getDefaultErrorCodeMessage(errorCode);
      } else {
        return {
          text: "dialog.error.unauthorizedMsg",
          params: null
        };
      }
    case 404: // Not found
      if (errorCode) {
        return getDefaultErrorCodeMessage(errorCode);
      } else {
        return {
          text: "dialog.error.itemNotFoundMsg",
          params: null
        };
      }
    default:
      return {
        text: "dialog.error.defaultMsg",
        params: null
      };
  }
}
function getDefaultErrorCodeMessage(errorCode) {
  switch (errorCode) {
    case 610:
    case 616:
    case 617:
    case 622:
      return {
        text: "dialog.error.emailExistsMsg",
        params: null
      };
    case 611: {
      return {
        text: "dialog.error.userNotFound",
        params: null
      };
    }
    case 613: {
      return {
        text: "dialog.error.credentialsMsg",
        params: null
      };
    }
    case 615:
      return {
        text: "dialog.error.accountNotActiveMsg",
        params: null
      };
    case 720: // Invalid token
      return {
        text: "dialog.error.invalidTokenMsg",
        params: null
      };
    case 721: // Token expired
      return {
        text: "dialog.error.tokenExpiredMsg",
        params: null
      };
    case 740: // Not found
      return {
        text: "dialog.error.itemNotFoundMsg",
        params: null
      };
    case 746:
      return {
        text: "dialog.error.communicationForNobody",
        params: null
      };
    default:
      return {
        text: "dialog.error.defaultMsg",
        params: null
      };
  }
}

export default {
  namespaced: true,
  strict,
  state,
  getters,
  mutations,
  actions
};
