const strict = true;

const getDefaultState = () => {
  return {
    active: false,
    title: {
      text: "",
      params: ""
    },
    message: {
      text: "",
      params: null
    },
    cancelBtn: {
      label: "",
      callback: null
    },
    confirmBtn: {
      label: "",
      callback: null,
      params: null
    },
    type: null // OPTIONS: PROCEED, DELETE, null
  };
};

const state = getDefaultState();

const getters = {
  isActive: state => state.active,
  getTitleText: state => state.title.text,
  getTitleParams: state => state.title.params,
  getMessageText: state => state.message.text,
  getMessageParams: state => state.message.params,
  getCancelBtnLabel: state => state.cancelBtn.label,
  getCancelBtnClb: state => state.cancelBtn.callback,
  getConfirmBtnLabel: state => state.confirmBtn.label,
  getConfirmBtnCallback: state => state.confirmBtn.callback,
  getConfirmBtnParams: state => state.confirmBtn.params,
  getType: state => state.type
};

const mutations = {
  RESET(state) {
    const s = getDefaultState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  SET_ACTIVE(state, active) {
    state.active = active;
  },
  SET_CONFIRM(
    state,
    {
      titleText = "confirm.defaultTitle",
      titleParams = null,
      messageText = "confirm.defaultMessage",
      messageParams = null,
      cancelBtnLabel = "button.cancel",
      cancelBtnCallback = null,
      confirmBtnLabel = "button.confirm",
      confirmBtnCallback = null,
      confirmBtnParams = null,
      type = null
    }
  ) {
    state.active = true;
    state.title.text = titleText;
    state.title.params = titleParams;
    state.message.text = messageText;
    state.message.params = messageParams;
    state.cancelBtn.label = cancelBtnLabel;
    state.cancelBtn.callback = cancelBtnCallback;
    state.confirmBtn.label = confirmBtnLabel;
    state.confirmBtn.callback = confirmBtnCallback;
    state.confirmBtn.params = confirmBtnParams;
    state.type = type;
  }
};

const actions = {
  cancelAction({ getters, commit }) {
    const cancelClb = getters.getCancelBtnClb;
    if (isFunction(cancelClb)) cancelClb();

    commit("RESET");
  },
  confirmAction({ getters, commit }) {
    const confirmClb = getters.getConfirmBtnCallback;
    if (isFunction(confirmClb)) confirmClb(getters.getConfirmBtnParams);

    commit("RESET");
  }
};

function isFunction(functionToCheck) {
  return (
    functionToCheck && {}.toString.call(functionToCheck) === "[object Function]"
  );
}

export default {
  namespaced: true,
  strict,
  state,
  getters,
  mutations,
  actions
};
