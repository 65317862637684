import swaggerClient from "./../plugins/swagger-client";

export default class PostService {
  retrieveUserPosts(parameters = {}) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Posts.retrieveUserPosts(
          parameters,
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  retrievePost(parameters) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Posts.retrievePost(
          parameters,
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  deleteComment(parameters) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Posts.deleteComment2(
          parameters,
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  deletePost(parameters) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Posts.deletePost(
          parameters,
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  removePostReport(condominiumId, postId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Posts.removePostReport(
          { condominiumId, postId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  removeReportPostComment(condominiumId, postId, commentId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Posts.removeReportPostComment(
          { condominiumId, postId, commentId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }
}
