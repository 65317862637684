export default {
  methods: {
    $_getUserProfilePhoto(profile) {
      return profile?.avatar?.url || "";
    },
    $_getUserFullName(profile) {
      if (profile?.name || profile?.surname) {
        return `${profile.name} ${profile.surname}`;
      } else return "-";
    },
    $_getUserTelephone(profile) {
      //   return profile && profile.telephone ? profile.telephone : "-";
      return profile?.telephone || "-";
    }
  }
};
