/* eslint prefer-promise-reject-errors: ["error", {"allowEmptyReject": true}] */
import services from "./../services";
import { SET_ERROR } from "./../errorHandler";

const getDefaultState = () => {
  return {
    maintainerUsers: [],
    currentMaintainerUser: {},
    filters: {
      search: "",
      userStatus: null
    },
    sortBy: "createdAt",
    sortDesc: true
  };
};
const state = getDefaultState();

const getters = {
  getMaintainerUsers: state => state.maintainerUsers,
  getCurrentMaintainerUser: state => state.currentMaintainerUser,

  getFilterSearch: state => state.filters.search,
  getFilterUserStatus: state => state.filters.userStatus,

  getSortBy: state => state.sortBy,
  getSortDesc: state => state.sortDesc
};

const mutations = {
  RESET(state) {
    const s = getDefaultState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  SET_MAINTAINER_USERS(state, maintainerUsers) {
    state.maintainerUsers = maintainerUsers;
  },
  SET_CURRENT_MAINTAINER_USER(state, currentMaintainerUser) {
    state.currentMaintainerUser = currentMaintainerUser;
  },
  SET_FILTER_SEARCH(state, search) {
    state.filters.search = search;
  },
  SET_FILTER_USER_STATUS(state, userStatus) {
    state.filters.userStatus = userStatus;
  },
  SET_SORT_BY(state, sortBy) {
    state.sortBy = sortBy;
  },
  SET_SORT_DESC(state, sortDesc) {
    state.sortDesc = sortDesc;
  }
};

const actions = {
  async retrieveMaintainerUsers({ commit, getters }) {
    try {
      const parameters = {
        type: "maintainer",
        search: getters.getFilterSearch
      };
      if (getters.getFilterUserStatus) {
        parameters.status = getters.getFilterUserStatus;
      }
      const response = await services.UserService.retrieveUsers(parameters);
      commit("SET_MAINTAINER_USERS", response);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async retrieveMaintainerUser(
    { commit, dispatch },
    { userId, needMaintainer = true }
  ) {
    try {
      const response = await services.UserService.retrieveUser(userId);
      commit("SET_CURRENT_MAINTAINER_USER", response.user);
      if (needMaintainer) await dispatch("retrieveMaintainer");
    } catch (error) {
      console.log(error?.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async retrieveMaintainers({ commit, getters }) {
    try {
      const parameters = {
        search: getters.getFilterSearch
      };
      if (getters.getFilterUserStatus) {
        parameters.status = getters.getFilterUserStatus;
      }
      const response = await services.MaintainerService.retrieveMaintainers(
        parameters
      );
      commit("SET_MAINTAINER_USERS", response);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async retrieveMaintainer({ commit, getters }) {
    const userInfo = getters.getCurrentMaintainerUser;
    const response = await services.MaintainerService.retrieveMaintainer(
      userInfo.profile.maintainerId
    );
    commit("SET_CURRENT_MAINTAINER_USER", {
      ...userInfo,
      generalInfo: response.maintainer
    });
  }
};

export default {
  namespaced: true,
  strict: true,
  state,
  getters,
  mutations,
  actions
};
