/* eslint prefer-promise-reject-errors: ["error", {"allowEmptyReject": true}] */
import services from "./../services";
import { SET_ERROR } from "./../errorHandler";

const getDefaultState = () => {
  return {
    administrator: {
      selected: null,
      options: []
    }
  };
};
const state = getDefaultState();

const getters = {
  getAdministratorSelected: state => state.administrator.selected,
  getAdministratorOptions: state => state.administrator.options
};

const mutations = {
  RESET(state) {
    const s = getDefaultState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  SET_ADMINISTRATOR_SELECTED(state, administrator) {
    state.administrator.selected = administrator;
  },
  SET_ADMINISTRATOR_OPTIONS(state, administrators) {
    state.administrator.options = administrators;
  }
};

const actions = {
  async retrieveActiveAdministrators({ commit, getters }) {
    try {
      const response = await services.UserService.retrieveUsers({
        type: "administrator",
        status: "active"
      });
      commit("SET_ADMINISTRATOR_OPTIONS", response);
      // Get latest administrator info (may be changed by a user)
      const administratorSelected = getters.getAdministratorSelected;
      if (administratorSelected) {
        const updatedData =
          response.find(
            administrator => administrator._id === administratorSelected._id
          ) || null;

        commit("SET_ADMINISTRATOR_SELECTED", updatedData);
      }
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  }
};

export default {
  namespaced: true,
  strict: true,
  state,
  getters,
  mutations,
  actions
};
