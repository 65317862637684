import swaggerClient from "./../plugins/swagger-client";

export default class IssueService {
  createIssue(condominiumId, payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Issues.createIssue(
          { condominiumId },
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  retrieveUserIssues(filters) {
    const parameters = filters || {};
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Issues.retrieveUserIssues(
          parameters,
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  retrieveIssues(parameters) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Issues.retrieveIssues(
          parameters,
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  retrieveIssue(condominiumId, issueId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Issues.retrieveIssue(
          { condominiumId, issueId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  updateIssue(condominiumId, issueId, payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Issues.updateIssue(
          { condominiumId, issueId },
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  deleteIssue(condominiumId, issueId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Issues.deleteIssue(
          { condominiumId, issueId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  addPhotos(condominiumId, issueId, photos) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Issues.addPhotos(
          { condominiumId, issueId },
          swaggerClient.buildRequestBody({ photos }),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  deletePhoto(condominiumId, issueId, photoId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Issues.deletePhoto(
          { condominiumId, issueId, photoId },
          swaggerClient.buildRequestBody(photoId),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  createComment(condominiumId, issueId, payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Issues.createIssueComment(
          { condominiumId, issueId },
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  removeIssueReport(condominiumId, issueId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Issues.removeIssueReport(
          { condominiumId, issueId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  removeReportIssueComment(condominiumId, issueId, commentId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Issues.removeReportIssueComment(
          { condominiumId, issueId, commentId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }
}
