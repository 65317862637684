/* eslint prefer-promise-reject-errors: ["error", {"allowEmptyReject": true}] */
import services from "./../services";
import { SET_ERROR } from "./../errorHandler";

const getDefaultState = () => {
  return {
    domiUsers: [],
    currentDomiUser: {},
    sortBy: "fullName",
    sortDesc: false,
    filters: {
      search: "",
      userStatus: null
    }
  };
};
const state = getDefaultState();

const getters = {
  getDomiUsers: state => state.domiUsers,
  getCurrentDomiUser: state => state.currentDomiUser,

  getSortBy: state => state.sortBy,
  getSortDesc: state => state.sortDesc,

  getFilterSearch: state => state.filters.search,
  getFilterUserStatus: state => state.filters.userStatus
};

const mutations = {
  RESET(state) {
    const s = getDefaultState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  SET_DOMI_USERS(state, domiUsers) {
    state.domiUsers = domiUsers;
  },
  SET_CURRENT_DOMI_USER(state, currentDomiUser) {
    state.currentDomiUser = currentDomiUser;
  },
  SET_SORT_BY(state, sortBy) {
    state.sortBy = sortBy;
  },
  SET_SORT_DESC(state, sortDesc) {
    state.sortDesc = sortDesc;
  },
  SET_FILTER_SEARCH(state, search) {
    state.filters.search = search;
  },
  SET_FILTER_USER_STATUS(state, userStatus) {
    state.filters.userStatus = userStatus;
  }
};

const actions = {
  async retrieveDomiUsers({ commit, getters }) {
    try {
      const parameters = {
        type: "domi",
        search: getters.getFilterSearch
      };
      if (getters.getFilterUserStatus) {
        parameters.status = getters.getFilterUserStatus;
      }
      const response = await services.UserService.retrieveUsers(parameters);
      commit("SET_DOMI_USERS", response);
    } catch (error) {
      console.log(error?.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async retrieveDomiUser({ commit }, userId) {
    try {
      const response = await services.UserService.retrieveUser(userId);
      commit("SET_CURRENT_DOMI_USER", response.user);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  }
};

export default {
  namespaced: true,
  strict: true,
  state,
  getters,
  mutations,
  actions
};
