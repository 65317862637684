import swaggerClient from "./../plugins/swagger-client";

export default class CondominiumService {
  createCondominium(payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Condominiums.createCondominium(
          {},
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  retrieveCondominiums(parameters = {}) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Condominiums.retrieveCondominiums(
          parameters,
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  retrieveCondominium(condominiumId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Condominiums.retrieveCondominium(
          { condominiumId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  updateCondominium(condominiumId, payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Condominiums.updateCondominium(
          { condominiumId },
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  deleteCondominium(condominiumId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Condominiums.deleteCondominium(
          { condominiumId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  changeCondominiumOwnership(condominiumId, userId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Condominiums.changeCondominiumOwnership(
          { condominiumId, userId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  inviteContacts(condominiumId, payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Condominiums.inviteContacts(
          { condominiumId },
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  inviteUsers(condominiumId, payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Condominiums.inviteUsers(
          { condominiumId },
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  retrieveMetrics(payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Condominiums.retrieveMetrics(
          payload,
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }
}
