/* eslint prefer-promise-reject-errors: ["error", {"allowEmptyReject": true}] */
import services from "./../services";
import { SET_ERROR } from "./../errorHandler";

const getDefaultState = () => {
  return {
    posts: [],
    currentPost: null,
    filters: {
      search: "",
      condominium: null,
      reported: false
    },
    sortBy: "createdAt",
    sortDesc: true,
    commentSortBy: "createdAt",
    commentDesc: true,
    likeSortBy: "createdAt",
    likeDesc: true
  };
};
const state = getDefaultState();

const getters = {
  getPosts: state => state.posts,
  getCurrentPost: state => state.currentPost,

  getFilterSearch: state => state.filters.search,
  getFilterCondominium: state => state.filters.condominium,
  getFilterReported: state => state.filters.reported,

  getSortBy: state => state.sortBy,
  getSortDesc: state => state.sortDesc,
  getCommentSortBy: state => state.commentSortBy,
  getCommentSortDesc: state => state.commentSortDesc,
  getLikeSortBy: state => state.likeSortBy,
  getLikeSortDesc: state => state.likeSortDesc
};

const mutations = {
  RESET(state) {
    const s = getDefaultState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  SET_POSTS(state, posts) {
    state.posts = posts;
  },
  SET_CURRENT_POST(state, post) {
    state.currentPost = post;
  },
  SET_FILTER_SEARCH(state, search) {
    state.filters.search = search;
  },
  SET_FILTER_CONDOMINIUM(state, condominium) {
    state.filters.condominium = condominium;
  },
  SET_FILTER_REPORTED(state, reported) {
    state.filters.reported = reported;
  },
  SET_SORT_BY(state, sortBy) {
    state.sortBy = sortBy;
  },
  SET_SORT_DESC(state, sortDesc) {
    state.sortDesc = sortDesc;
  },
  SET_COMMENT_SORT_BY(state, sortBy) {
    state.commentSortBy = sortBy;
  },
  SET_COMMENT_SORT_DESC(state, sortDesc) {
    state.commentSortDesc = sortDesc;
  },
  SET_LIKE_SORT_BY(state, sortBy) {
    state.likeSortBy = sortBy;
  },
  SET_LIKE_SORT_DESC(state, sortDesc) {
    state.likeSortDesc = sortDesc;
  }
};

const actions = {
  async retrieveUserPosts({ commit, getters }) {
    try {
      const parameters = {
        search: getters.getFilterSearch,
        isReported: getters.getFilterReported
      };
      if (getters.getFilterCondominium) {
        parameters.condominiumId = getters.getFilterCondominium._id;
      }
      const response = await services.PostService.retrieveUserPosts(parameters);
      commit("SET_POSTS", response);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async retrievePost({ commit }, parameters) {
    try {
      const response = await services.PostService.retrievePost(parameters);
      commit("SET_CURRENT_POST", response.post);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, { fallbackRoute: "Posts" });
      return Promise.reject();
    }
  },
  async deleteComment({ commit }, parameters) {
    try {
      await services.PostService.deleteComment(parameters);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async deletePost({ commit }, parameters) {
    try {
      await services.PostService.deletePost(parameters);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async removePostReport({ commit }, { condominiumId, postId }) {
    try {
      await services.PostService.removePostReport(condominiumId, postId);
    } catch (error) {
      SET_ERROR(error, {});
      return Promise.reject(error.response?.body || error);
    }
  },
  async removeReportPostComment(
    { commit },
    { condominiumId, postId, commentId }
  ) {
    try {
      await services.PostService.removeReportPostComment(
        condominiumId,
        postId,
        commentId
      );
    } catch (error) {
      SET_ERROR(error, {});
      return Promise.reject(error.response?.body || error);
    }
  }
};

export default {
  namespaced: true,
  strict: true,
  state,
  getters,
  mutations,
  actions
};
